var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Tabs',{staticClass:"activity-resource-view h-100 p-10",attrs:{"value":_vm.tab,"type":"card"},on:{"on-click":_vm.changeTab}},[_c('TabPane',{attrs:{"name":"once","label":"添加资源"}},[_c('div',{staticClass:"m-b-5 f-bold"},[_vm._v("资源类型:")]),_c('Select',{staticClass:"w-15",attrs:{"label-in-value":""},on:{"on-change":_vm.getResourceType},model:{value:(_vm.resourceType),callback:function ($$v) {_vm.resourceType=$$v},expression:"resourceType"}},_vm._l((_vm.types),function(item,index){return _c('Option',{key:index,attrs:{"value":item.type}},[_vm._v(_vm._s(item.title))])}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resourceType),expression:"resourceType"}]},[_c('div',{staticClass:"m-t-10 m-b-5 f-bold"},[_vm._v(_vm._s(_vm.resourceLabel)+":")]),(_vm.resourceType == 'pan/text')?_c('Input',{staticClass:"w-70",attrs:{"type":"textarea","autosize":{ minRows: 3 },"placeholder":"请输入描述文字"},model:{value:(_vm.context),callback:function ($$v) {_vm.context=$$v},expression:"context"}}):_vm._e(),_c('GeoFile',{directives:[{name:"show",rawName:"v-show",value:(_vm.resourceType != 'pan/text'),expression:"resourceType != 'pan/text'"}],staticClass:"w-30",attrs:{"text":`选择${_vm.resourceLabel}`,"upload-txt":`上传${_vm.resourceLabel}`,"multiple":false,"param":{
          folderId: _vm.folderIds[_vm.resourceType],
          userId: _vm.userId,
        }},model:{value:(_vm.context),callback:function ($$v) {_vm.context=$$v},expression:"context"}})],1),_c('div',{staticClass:"m-t-20"},[_c('Button',{attrs:{"type":"success"},on:{"click":_vm.saveResource}},[_vm._v("添加资源")]),_c('Button',{staticClass:"m-l-5",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")])],1)],1),_c('TabPane',{attrs:{"name":"branch","label":"批量添加"}},[_c('Row',{staticClass:"h-100",attrs:{"gutter":10}},[_c('Col',{attrs:{"span":"6"}},[_c('div',{staticClass:"m-b-5 f-bold"},[_vm._v("资源类型:")]),_c('Select',{staticClass:"w-60",attrs:{"label-in-value":"","placeholder":"请选择资源类型","clearable":""},on:{"on-change":_vm.getResourceType},model:{value:(_vm.resourceType),callback:function ($$v) {_vm.resourceType=$$v},expression:"resourceType"}},_vm._l((_vm.types),function(item,index){return _c('Option',{key:index,attrs:{"value":item.type}},[_vm._v(_vm._s(item.title))])}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resourceType),expression:"resourceType"}]},[_c('div',{staticClass:"m-t-10 m-b-5 f-bold"},[_vm._v(_vm._s(_vm.resourceLabel)+":")]),(_vm.resourceType == 'pan/text')?_c('Input',{attrs:{"type":"textarea","autosize":{ minRows: 5 },"placeholder":"请输入描述文字"},model:{value:(_vm.context),callback:function ($$v) {_vm.context=$$v},expression:"context"}}):_vm._e(),_c('GeoFile',{directives:[{name:"show",rawName:"v-show",value:(_vm.resourceType != 'pan/text'),expression:"resourceType != 'pan/text'"}],attrs:{"text":`选择${_vm.resourceLabel}`,"upload-txt":`上传${_vm.resourceLabel}`,"multiple":false,"param":{
              folderId: _vm.folderIds[_vm.resourceType],
              userId: _vm.userId,
            }},model:{value:(_vm.context),callback:function ($$v) {_vm.context=$$v},expression:"context"}})],1),_c('div',{staticClass:"m-t-20"},[_c('Button',{attrs:{"type":"success"},on:{"click":_vm.saveData}},[_vm._v("添加")]),_c('Button',{staticClass:"m-l-5",attrs:{"type":"warning"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('Button',{staticClass:"m-l-5",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")])],1)],1),(_vm.resources.length)?_c('Col',{staticClass:"h-100",attrs:{"span":"18"}},[_c('Row',{staticClass:"f-15 f-bold res-header",staticStyle:{"margin-left":"0","margin-right":"0"},attrs:{"type":"flex","gutter":10}},[_c('Col',{staticClass:"text-c",attrs:{"span":"2"}},[_vm._v("序号")]),_c('Col',{attrs:{"span":"3"}},[_vm._v("资源类型")]),_c('Col',{attrs:{"span":"16"}},[_vm._v("资源内容")]),_c('Col',{attrs:{"span":"3"}},[_vm._v("操作")])],1),_c('div',{staticClass:"res-list"},_vm._l((_vm.resources),function(item,index){return _c('Row',{key:index,staticClass:"m-t-10 b-b-1 p-b-10",attrs:{"type":"flex","gutter":10}},[_c('Col',{staticClass:"text-c",attrs:{"span":"2"}},[_vm._v(_vm._s(index + 1)+"、")]),_c('Col',{attrs:{"span":"3"}},[_vm._v(_vm._s(item.resourceType))]),_c('Col',{attrs:{"span":"16"}},[_vm._v(_vm._s(item.context))]),_c('Col',{attrs:{"span":"3"}},[_c('Button',{attrs:{"type":"error","size":"small","icon":"md-close"},on:{"click":function($event){return _vm.delItem(index)}}})],1)],1)}),1),_c('div',{staticClass:"m-t-10"},[_c('Button',{attrs:{"type":"success"},on:{"click":_vm.branchSaveResource}},[_vm._v("批量添加资源")]),_c('Button',{staticClass:"m-l-5",on:{"click":_vm.clearData}},[_vm._v("清除")])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }