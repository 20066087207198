<template>
  <Tabs
    :value="tab"
    @on-click="changeTab"
    type="card"
    class="activity-resource-view h-100 p-10"
  >
    <TabPane name="once" label="添加资源">
      <div class="m-b-5 f-bold">资源类型:</div>
      <Select
        v-model="resourceType"
        class="w-15"
        @on-change="getResourceType"
        label-in-value
      >
        <Option
          v-for="(item, index) in types"
          :key="index"
          :value="item.type"
          >{{ item.title }}</Option
        >
      </Select>
      <div v-show="resourceType">
        <div class="m-t-10 m-b-5 f-bold">{{ resourceLabel }}:</div>
        <Input
          v-model="context"
          type="textarea"
          :autosize="{ minRows: 3 }"
          placeholder="请输入描述文字"
          class="w-70"
          v-if="resourceType == 'pan/text'"
        />
        <GeoFile
          v-model="context"
          :text="`选择${resourceLabel}`"
          :upload-txt="`上传${resourceLabel}`"
          :multiple="false"
          :param="{
            folderId: folderIds[resourceType],
            userId: userId,
          }"
          v-show="resourceType != 'pan/text'"
          class="w-30"
        />
      </div>
      <div class="m-t-20">
        <Button type="success" @click="saveResource">添加资源</Button>
        <Button @click="$router.back()" class="m-l-5">返回</Button>
      </div>
    </TabPane>
    <TabPane name="branch" label="批量添加">
      <Row :gutter="10" class="h-100">
        <Col span="6">
          <div class="m-b-5 f-bold">资源类型:</div>
          <Select
            v-model="resourceType"
            @on-change="getResourceType"
            label-in-value
            placeholder="请选择资源类型"
            clearable
            class="w-60"
          >
            <Option
              v-for="(item, index) in types"
              :key="index"
              :value="item.type"
              >{{ item.title }}</Option
            >
          </Select>
          <div v-show="resourceType">
            <div class="m-t-10 m-b-5 f-bold">{{ resourceLabel }}:</div>
            <Input
              v-model="context"
              type="textarea"
              :autosize="{ minRows: 5 }"
              placeholder="请输入描述文字"
              v-if="resourceType == 'pan/text'"
            />
            <GeoFile
              v-model="context"
              :text="`选择${resourceLabel}`"
              :upload-txt="`上传${resourceLabel}`"
              :multiple="false"
              :param="{
                folderId: folderIds[resourceType],
                userId: userId,
              }"
              v-show="resourceType != 'pan/text'"
            />
          </div>

          <div class="m-t-20">
            <Button type="success" @click="saveData">添加</Button>
            <Button type="warning" @click="reset" class="m-l-5">重置</Button>
            <Button @click="$router.back()" class="m-l-5">返回</Button>
          </div>
        </Col>
        <Col span="18" class="h-100" v-if="resources.length">
          <Row type="flex" :gutter="10" class="f-15 f-bold res-header" style="margin-left: 0; margin-right: 0;">
            <Col span="2" class="text-c">序号</Col>
            <Col span="3">资源类型</Col>
            <Col span="16">资源内容</Col>
            <Col span="3">操作</Col>
          </Row>
          <div class="res-list">
            <Row type="flex" :gutter="10" v-for="(item, index) in resources" :key="index" class="m-t-10 b-b-1 p-b-10">
            <Col span="2" class="text-c">{{ index + 1 }}、</Col>
            <Col span="3">{{ item.resourceType }}</Col>
            <Col span="16">{{ item.context }}</Col>
            <Col span="3"><Button type="error" size="small" icon="md-close" @click="delItem(index)"></Button></Col>
            </Row>
          </div>
          <div class="m-t-10">
            <Button type="success" @click="branchSaveResource">批量添加资源</Button>
            <Button @click="clearData" class="m-l-5">清除</Button>
          </div>
        </Col>
      </Row>
    </TabPane>
  </Tabs>
</template>

<script>
import { addResource } from "@/libs/api/activity";
import { mapState } from "vuex";
import { util } from "@/libs";
export default {
  data() {
    return {
      tab: "once",
      resourceType: "",
      resourceLabel: "",
      context: null,
      folderIds: { "pan/gpx": 2171, "pan/image": 2166, "pan/epub": 2164 },
      types: [
        { title: "轨迹", type: "pan/gpx" },
        { title: "图片", type: "pan/image" },
        { title: "文字", type: "pan/text" },
        { title: "课程", type: "pan/epub" },
      ],
      resources: [],
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
    }),
  },
  methods: {
    // 切换
    changeTab(name) {
      if (this.resourceType) {
        this.resourceType = "";
      }
      if (this.resourceLabel) {
        this.resourceLabel = "";
      }
      if (this.context) {
        this.context = "";
      }
    },
    // 获取资源类型
    getResourceType(item) {
      if (item) {
        this.resourceLabel = item.label;
      } else {
        this.resourceLabel = "文件";
      }
      if (this.context) {
        this.context = "";
      }
    },

    // 添加活动资源
    saveResource() {
      if (!this.resourceType) {
        this.$Message.warning("请先添加资源类型！");
        return;
      }
      if (!this.context) {
        this.$Message.warning(`请先选择${this.resourceLabel}！`);
        return;
      }
      const activityId = this.$route.query.id;
      let param = {
        activity_id: activityId,
        resourceType: this.resourceType,
        context: this.context,
        resourceId:
          this.resourceType == "pan/text"
            ? ""
            : this.context.split('/')[this.context.split('/').length - 1]
      };

      addResource(param).then((res) => {
        if (res.code == "HA0200" && res.data) {
          this.$Message.success("添加成功！");
          // this.$router.push({
          //   path: "/config/dataManage/activity/activityResource",
          //   query: { id: activityId },
          // });
        } else {
          this.$Message.error(res.msg);
        }
      });
    },

    // 添加到资源
    saveData() {
      if (!this.resourceType) {
        this.$Message.warning("请先添加资源类型！");
        return;
      }
      if (!this.context) {
        this.$Message.warning(`请先选择${this.resourceLabel}！`);
        return;
      }
      const activityId = this.$route.query.id;
      let param = {
        activity_id: activityId,
        resourceType: this.resourceType,
        context: this.context,
        resourceId:
          this.resourceType == "pan/text"
            ? ""
            : this.context.split('/')[this.context.split('/').length - 1]
      };
      this.resources.push(param);
      this.resourceType = null;
      this.context = "";
      this.resourceLabel = "";
    },

    // 重置
    reset() {
      this.resourceType = null;
      this.context = "";
      this.resourceLabel = "";
    },
    // 删除
    delItem(index) {
      this.resources.splice(index, 1)
    },
    // 批量添加活动资源
    branchSaveResource() {
      if(this.resources.length) {
        this.resources.map((item, index) => {
          addResource(item).then((res) => {
            if(this.resources.length - 1 == index) {
              this.$Message.success("添加成功！")
            }
          })
        })
      }
    },
    // 清除数据
    clearData() {
      this.resources = []
    }
  },
};
</script>

<style lang="less">
.activity-resource-view {
  position: relative;
  box-sizing: border-box;
  .ivu-tabs-content {
    width: calc(100% - 20px);
    height: calc(100% - 66px);
    position: absolute;
    .res-header {
      box-shadow: 0px 2px 5px 0 #eee;
      height: 40px;
      line-height: 40px;
    }
    .res-list {
      max-height: calc(100% - 100px);
      overflow-y: scroll;
      overflow-x: hidden;
      border: solid 1px #eee;
    }
  }
}
</style>
